import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const state = {
  locale: "ja"
}

const mutations = {
  setLocale (state, locale) {
    state.locale = locale
  },
}

const getters = {}

const actions = {}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [
    createPersistedState() // store情報の永続化
  ]
})