import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './lang'
import vuetify from './plugins/vuetify'

import vueScrollTo from 'vue-scrollto'

Vue.config.productionTip = false

Vue.use(vueScrollTo, {
  duration: 1000,
  easing: "ease"
})

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
