import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF7B7B',
        secondary: '#616372',
      },
    },
    options: { customProperties: true }
  }
});
