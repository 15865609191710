import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import locales from './locales'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.state.locale,
  messages: (() => {
    let messages = {};
    for (let locale in locales) {
      messages[locale] = require('./' + locale+'.json')
    }
    return messages;
  })()
})

export default i18n